<template>
  <header class="container flex flex-shrink auto-rows-auto grid-cols-12 flex-col gap-6 p-6 sm:p-0 lg:grid">
    <UiCard
      :variation="backgroundColor"
      class="order-1 col-span-4 flex flex-col justify-between"
    >
      <h1 class="lg:text-7xl">
        {{ title }}
      </h1>
      <UiLink
        variation="white"
        class="text-center"
        :to="{name: 'event'}"
      >
        Bekijk alle evenementen
      </UiLink>
    </UiCard>
    <div class="order-4 col-span-4 mx-auto lg:order-2">
      <UiMedia
        v-if="images?.[0]"
        :src="images[0].url"
        :alt="images[0].title ?? ''"
        :img-attrs="{class: 'aspect-[9/10] object-cover', style: {clipPath: 'polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)'}}"
      />
    </div>
    <NuxtLink
      v-if="nextEvent"
      :to="{name: 'event-slug', params: {slug: nextEvent.slug}}"
      class="relative order-2 col-span-4 row-span-2 lg:order-3"
    >
      <video
        v-if="video?.[0]"
        :title="video[0].title"
        autoplay
        loop
        muted
        playsinline
        height="100%"
        width="100%"
        class="h-full max-h-none rounded-4xl object-cover max-sm:aspect-[3/4] xl:max-h-[47.25rem]"
      >
        <source
          :src="url + video[0].url"
          type="video/mp4"
        >
      </video>
      <NuxtPicture
        v-else-if="nextEvent?.active_image"
        :src="nextEvent.active_image.url"
        :alt="nextEvent.active_image.title ?? ''"
        :img-attrs="{class: 'rounded-4xl h-full object-cover'}"
      />
      <UiCard
        variation="white"
        class="absolute inset-x-3 bottom-3 flex flex-col p-4"
      >
        <p>Volgend event</p>
        <strong>{{ nextEvent.title }}</strong>
        <strong>{{ dateFormat(nextEvent.timeslots[0].date) }} - {{ nextEvent.timeslots[0].location.title }}</strong>
      </UiCard>
    </NuxtLink>
    <div class="order-5 col-span-2 mx-auto lg:order-4">
      <UiMedia
        v-if="images?.[1]"
        :src="images[1].url"
        :alt="images[1].title ?? ''"
        :img-attrs="{class: 'rounded-4xl object-cover size-full'}"
      />
    </div>
    <UiCard
      variation="white"
      class="order-3 col-span-6 lg:order-5"
    >
      <p>
        {{ subTitle }}
      </p>
    </UiCard>
  </header>
</template>

<script setup lang="ts">
import type {Event, Scalars} from '~/graphql/graphql';

defineProps<{
  title: string
  subTitle?: string
  images?: object
  nextEvent: Event
  video: Array<Scalars['PrivateFile']['output']>
  backgroundColor: 'blue' | 'green'
}>();

const {url} = useSofieConfig();
</script>
